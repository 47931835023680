import { defineStore } from 'pinia'
import axios from 'axios'
import { Location } from '../interfaces/Location.interface'
import { WorkItem } from '../interfaces/WorkItem.interface'
import Report from './utils/report'

const $baseUrl = process.env.VUE_APP_API_BASE_URL;
const $topSecretKey = process.env.VUE_APP_API_SECRET_KEY;
const $serviceFileFolder = process.env.VUE_APP_SERVICE_FILE_FOLDER;

export const useLocationPlanStore = defineStore('locationPlan', {
    state: () => ({
        location: {} as Location,
        locationFound: false,
        loading: false,
        misc: '',
        contractor: 0,
        error: '',
    }),
    actions: {
        async setContractor(contractor: number): Promise<void> {
            this.contractor = contractor;
        },
        async getLocationFromQRCode(qrCodeId: string) {
            this.loading = true;
            const path = $baseUrl + '/items/Locations?filter[qrcode_id][_eq]=' + qrCodeId + '&fields=id,Title,WorkItems,WorklistFile,sort_type,sort,OperationsMap,WorkItems.*,WorkItems.Service.*';
            const options = {
                headers: {
                    Authorization: 'Bearer ' + $topSecretKey
                }
            }
            axios.get(path, options)
            .then((response: {data: any}) => {
                if (response.data.data.length === 0) {
                    this.error = "Ingen lokation fundet på adressen, prøv at gå lidt tættere på.";
                    return;
                }

                this.location = response.data.data[0];
                this.location.WorkItems.map((workItem: WorkItem) => {
                    workItem.checked = false;
                    workItem.images = [];
                    return workItem;
                });
                this.locationFound = true;
            })
            .catch((error:any) => {
                this.error = 'fejl:' + error;
            })
            .finally(() => {
                this.loading = false;
            });
        },

        async getLocation(addressString: string, isQRCode: boolean) {
            this.loading = true;
            let path = $baseUrl + '/items/Locations?filter[Address][_eq]=' + addressString + '&fields=id,Title,WorkItems,WorklistFile,Address,sort_type,sort,OperationsMap,WorkItems.*,WorkItems.Service.*';
            if (isQRCode) {
                path = $baseUrl + '/items/Locations?filter[id][_eq]=' + addressString + '&fields=id,Title,WorkItems,WorklistFile,Address,sort_type,sort,OperationsMap,WorkItems.*,WorkItems.Service.*';
            }
            
            const options = {
                headers: {
                    Authorization: 'Bearer ' + $topSecretKey
                }
            }
            axios.get(path, options)
            .then((response: {data: any}) => {
                if (response.data.data.length === 0) {
                    this.error = "Ingen lokation fundet på adressen, prøv at gå lidt tættere på.";
                    return;
                }


                this.location = response.data.data[0];
                this.location.WorkItems = this.location.WorkItems.filter((workItem: WorkItem) => {
                    return workItem.Service != null;
                });

                this.location.WorkItems.map((workItem: WorkItem) => {
                    workItem.checked = false;
                    workItem.images = [];
                    return workItem;
                });

                


                this.location.WorkItems.sort((a: WorkItem, b: WorkItem) => {

                    let nameA: number|string = a.Service.Title.toUpperCase();
                    let nameB: number|string = b.Service.Title.toUpperCase();

                    if (this.location.sort_type !=='alphabetical') {
                        nameA = a.sort;
                        nameB = b.sort;
                    }

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                this.locationFound = true;
            })
            .catch((error:any) => {
                this.error = 'fejl:' + error;
            })
            .finally(() => {
                this.loading = false;
            });
        },

        async toggleWorkItem(workItem: WorkItem): Promise<void> {
            workItem.checked = !workItem.checked;
        },

        async addSnapshotToWorkItem(workItem: WorkItem, image: Blob): Promise<void> {
            workItem.images.push(image);
        },

        async deleteSnapshotFromWorkItem(workItem: WorkItem, image: number): Promise<void> {
            workItem.images.splice(image, 1);
        },

        async submitReport(): Promise<void> {
            return new Report(this.location, this.contractor, this.misc, $baseUrl, $topSecretKey, $serviceFileFolder).sendReport();
        }
    },
})