import { v4 as uuidv4 } from 'uuid';

import axios from 'axios'
import { Location } from '../../interfaces/Location.interface'
import { WorkItem } from '../../interfaces/WorkItem.interface'

export default class Report {
    private location: Location;
    private $baseUrl: string;
    private $topSecretKey: string;
    private $serviceFileFolder: string;
    private fileUploadPath: string;
    private fileUploadOptions: object;
    private reportUploadPath: string;
    private reportUploadOptions: object;
    private contractor: number;
    private misc: string;

    constructor(location: Location, contractor: number, misc: string, $baseUrl: string, $topSecretKey: string, $serviceFileFolder:string) {
        this.location = location;
        this.contractor = contractor;
        this.$baseUrl = $baseUrl;
        this.misc = misc;
        this.$topSecretKey = $topSecretKey;
        this.$serviceFileFolder = $serviceFileFolder;
        this.fileUploadPath = this.$baseUrl + '/files';
        this.reportUploadPath = this.$baseUrl + '/items/Reports';
        this.fileUploadOptions = {
            headers: {
                Authorization: 'Bearer ' + this.$topSecretKey,
                'Content-Type': `multipart/form-data`
            }
        }
        this.reportUploadOptions = {
            headers: {
                Authorization: 'Bearer ' + this.$topSecretKey,
            }
        }
    }

    public async sendReport(): Promise<void> {
        const selectedWorkItems = this.location.WorkItems.filter((workItem: WorkItem) => {
            return workItem.checked;
        });

        const reportItem = await Promise.all(selectedWorkItems.map(async (workItem) => {
            if (!workItem.checked) return;
            return await this.createReportItems(workItem);
        }));

        const report = {
            "Location": this.location.id,
            "Contractor": this.contractor,
            "ReportItems": reportItem,
            "Misc": this.misc,
        }

        return new Promise((resolve, reject) => {
            axios.post(this.reportUploadPath, report, this.reportUploadOptions)
            .then((response: {data: any}) => {
                resolve();
            })
            .catch((error:any) => {
                console.error(error);
                reject();
            })
        });

    }

    private async createReportItems(workItem: any): Promise<any> {

        const files = await Promise.all(workItem.images.map(async (imageBlob: Blob) => {
        return await this.uploadFile(imageBlob);
        }));

        const reportItem = {
            Service: workItem.Service.Title,
            WorkItem: workItem.id,
            Files: files,
        }
        return new Promise((resolve): void => {
            resolve(reportItem);
        });
    }

    private async uploadFile(imageBlob: Blob): Promise<object> {
        const formData = new FormData();
        const fileName = uuidv4() + '.jpg';
        const imageFile = new File([imageBlob], fileName, {
            type: "image/png",
        });

        formData.append('folder', this.$serviceFileFolder);
        formData.append('file', imageFile, fileName);
        
        return new Promise((resolve) => {
            axios.post(this.fileUploadPath, formData, this.fileUploadOptions)
            .then((response: {data: any}) => {
                resolve({"directus_files_id":response.data.data.id});
            })
            .catch((error:any) => {
                console.error(error);
            })
        });
    }
}