import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ScanView from '../views/ScanView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/scan',
    name: 'scan',
    component: ScanView
  },
  {
    path: '/l/:id',
    name: 'location',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (to.query?.hash) {
    console.log('Bla');
    localStorage.setItem('scannedAt_'+to.params?.id ?? '00', atob(String(to.query.hash)));
    
    delete(to.query.hash);
    router.replace(to)
  }

  if (to.path != '/scan' && Number(localStorage.getItem('scannedAt_'+to.params?.id ?? '00') ?? 0) < (Math.floor(Date.now() / 1000) - 60*5)) {
    return '/scan';
  }

  return true;
})

export default router
