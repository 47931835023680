import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import Camera from "simple-vue-camera";
import router from './router/index';



declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $baseUrl: string;
        $topSecretKey: string;
        getLocation: any;
        $dawaBaseUrl: string;
    }
}

const pinia = createPinia()
const app = createApp(App);

app.use(router);
app.use(VueAxios, axios)
app.use(BootstrapIconsPlugin)
app.use(pinia)
app.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: 'havia-frontoffice',
      serverUrl: 'https://logs.streamoffice.app',
    }
})
app.component("CameraView", Camera);
app.provide('axios', app.config.globalProperties.axios)
app.provide('$baseUrl', process.env.VUE_APP_API_BASE_URL)
app.provide('$dawaBaseUrl', process.env.VUE_APP_DAWA_BASE_URL)
app.provide('$topSecretKey', process.env.VUE_APP_API_SECRET_KEY)

router.isReady().then(() => {
    app.mount('#app');
});