<template>
    <div class="modal fade show" id="confirmDialog" tabindex="-1" aria-labelledby="confirmDialogTitle" aria-modal="true" role="dialog" style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Rapporten er blevet sendt</h5>
            </div>
            <div class="modal-body">
                <p>Rapporten er blevet afsendt, scan QR koden igen for at oprette en ny.</p>
            </div>
            <div class="modal-footer">
            </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script lang="ts" setup>
   
</script>

<style scoped> 

</style>